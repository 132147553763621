import React from 'react'
import Layout from '../../components/layout'
import { WordRapCreator } from '../../components/ChallengeCreators'

const WordRapCreatorPage = ({ learningGoalId }) => (
  <Layout>
    <WordRapCreator learningGoalId={learningGoalId} />
  </Layout>
)

export default WordRapCreatorPage
